<template>
  <div>
     <el-row :gutter="15">
        <el-col :span="12">
          <el-card>
            <alter-form labelWidth="160px" ref="firFormData" :customeButton="true" :watchImageVisible="false" @handleChange="handleChange" :formData="formData" :collapseLength="collapseLength"></alter-form>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <alter-form labelWidth="160px" ref="secFormData" :customeButton="true" :watchImageVisible="false" @handleChange="handleChange" :formData="secFormData" :collapseLength="collapseLength"></alter-form>
          </el-card>
           <el-card v-for="(item, index) in package_items" :key="index" class="margintop10">
            <alter-form labelWidth="160px" ref="tirFormData" :modifyData="item || {}" :customeButton="true" :watchImageVisible="false" :formData="tirFormData" :collapseLength="collapseLength"></alter-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card>
        <el-row type="flex" justify="center">
          <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
